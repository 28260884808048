import React from "react";
import "./dasMasSelector.css";

import cash from "./cashY.png";
import card from "./cardY.png";

export default function DasMasSelectorButton({ onClick, children, icon }) {
  const iconElement = () => {
    switch (icon) {
      case "cash":
        return <img src={cash} alt=""></img>;

      case "card":
      default:
        return <img src={card} alt=""></img>;
    }
  };

  return (
    <button className="das-mas-selector-button" onClick={onClick}>
      {iconElement()}
      <div>{children}</div>
    </button>
  );
}
