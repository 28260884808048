import React from "react";
import logo from "./pagos-online-white.svg";
import "./Header.css";

const Header = (props) => {
  const { price } = props;

  return (
    <header className="header">
      <img alt="Pagos Online" src={logo} />
      <span>{price}</span>
    </header>
  );
};

export default Header;
