import React from "react";
import logo from "../zenlogo.png";

const Loader = (props) => {
  const { price } = props;

  return (
    <div className="loaderContainer">
      <div className="loadBar"></div>
      <img src={logo} className="pageLogo" alt="Cargando…"></img>
    </div>
  );
};

export default Loader;
