import React, { useState, useEffect } from "react";
import "./App.css";
import PaymentSelector from "./components/PaymentSelector";
import DasMasSelector from "./components/DasMasSelector";
import PaymentForm from "./components/PaymentForm";
import Header from "./components/Header";
import Loader from "./components/Loader";
import { getLinkInfo, getImageMeta } from "./Services";

const DASMAS_ORGANIZATION_ID = 154;

const App = () => {
  const [step, setStep] = useState(1);
  const [usesCard, setUsesCard] = useState(1);
  const [linkInfo, setLinkInfo] = useState({});
  const [imgInfo, setImgInfo] = useState({
    width: null,
    height: null,
  });

  const nextStep = (isCardMethod) => {
    document.querySelector(".content").style.opacity = 0;
    document.querySelector(".content").style.transform = "translateX(-100px)";
    setUsesCard(isCardMethod);
    setTimeout(() => setStep(2), 200);
  };

  useEffect(() => {
    getLinkInfo().then(setLinkInfo);
  }, []);

  const isDasMas =
    linkInfo?.organization &&
    linkInfo.organization.id === DASMAS_ORGANIZATION_ID;

  useEffect(() => {
    if (linkInfo.image != null) {
      getImageMeta(linkInfo.image, (width, height) => {
        setImgInfo({ width, height });
      });
    }
  }, [linkInfo]);

  return (
    <>
      {!isDasMas && <Header />}
      <div style={{ display: "flex" }}>
        {
          /* imgInfo.height > imgInfo.width */
          linkInfo.image && (
            <div
              className={imgInfo.height > imgInfo.width ? "" : "imgContainer"}
            >
              <img
                src={linkInfo.image}
                className={
                  imgInfo.height > imgInfo.width
                    ? "verticalImg"
                    : "landscapeImg"
                }
                alt=""
              />
            </div>
          )
        }
        <div className="app">
          {Object.keys(linkInfo).length === 0 && linkInfo !== 400 && <Loader />}
          {step === 1 && !isDasMas && (
            <PaymentSelector
              linkInfo={linkInfo}
              nextStep={nextStep}
              setUsesCard={setUsesCard}
            />
          )}
          {step === 1 && isDasMas && (
            <DasMasSelector
              linkInfo={linkInfo}
              nextStep={nextStep}
              setUsesCard={setUsesCard}
            />
          )}
          {step === 2 && (
            <PaymentForm
              usesCard={usesCard}
              setStep={setStep}
              isDasMas={isDasMas}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default App;
