import axios from 'axios';
const api = process.env.REACT_APP_BASE_URL_API;

const getHash = () => {
    return getUrlParameter("hash");
};

const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


export const getLinkInfo = async () => {
    const hashId = getHash();
    if (hashId.length === 0)
        return 400;
    return axios({
        method: 'GET',
        url: `${api}/v1/zen-link/from-hash/${hashId}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response.status;
    });
}


export const getPaymentLinks = async (fname, lname, email, dni, areacode, phonenumber) => {
    return axios({
        method: 'POST',
        url: `${api}/v1/zen-link/generate-payment-links`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: simpleDto(fname, lname, email, dni, areacode, phonenumber)
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response.status;
    });
}


const simpleDto = (fname, lname, email, dni, areacode, phonenumber) => {
    return {
        "hash": getHash(),
        "contact": {
            "firstName": fname,
            "lastName": lname,
            "email": email,
            "holderDocumentNumber": dni.toString(),
            "phoneAreaCode": areacode,
            "phoneNumber": phonenumber
        }
    }
}

export const getImageMeta = (url, callback) => {
        const img = new Image();
        img.src = url;
        img.onload = function() { callback(this.width, this.height); }
}
