import React from "react";
import { formatMoney } from "../helpers";
import DasMasSelectorButton from "./DasMasSelectorButton";
import "./dasMasSelector.css";

const DasMasSelector = (props) => {
  const { nextStep, setUsesCard } = props;
  let { linkInfo } = props;
  const name = linkInfo.organization ? linkInfo.organization.name : "";
  const ahora12Enabled = linkInfo.ahora12Enabled
    ? linkInfo.ahora12Enabled
    : false;
  const showPaymentOptionsWhenAhora12IsEnabled =
    linkInfo.showPaymentOptionsWhenAhora12IsEnabled;

  if (linkInfo === 400) {
    return (
      <div className="content das-mas">
        <h2>Error</h2>
        <p>{linkInfo.groupName}</p>
        <h3>No encontramos datos de pago asociados a este link.</h3>
      </div>
    );
  }

  const showCardAndCash = ahora12Enabled
    ? showPaymentOptionsWhenAhora12IsEnabled
    : true;

  return (
    <div
      className="content das-mas"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        textAlign: "center",
      }}
    >
      <h2 style={{ fontSize: "24px", fontWeight: 600, color: "black" }}>
        {linkInfo.description}
      </h2>
      <div style={{ fontSize: "18px", color: "#555" }} className="group-name">
        {linkInfo.groupName}
      </div>
      <div style={{ fontSize: "18px", color: "#555" }}>
        {"Pagar "}
        <span style={{ color: "black", fontWeight: 500 }}>
          {formatMoney(linkInfo.amount)}
        </span>
        {" a "}
        <span style={{ color: "black", fontWeight: 500 }}>{name}</span>
      </div>
      <div> </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "32px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showCardAndCash && (
          <>
            <DasMasSelectorButton
              icon="cash"
              onClick={() => {
                nextStep();
                setUsesCard(0);
              }}
            >
              Efectivo
            </DasMasSelectorButton>
            <DasMasSelectorButton
              icon="card"
              onClick={() => {
                nextStep();
                setUsesCard(1);
              }}
            >
              Tarjeta
            </DasMasSelectorButton>
          </>
        )}
        {ahora12Enabled && (
          <DasMasSelectorButton
            icon="card"
            onClick={() => {
              nextStep();
              setUsesCard(1);
            }}
          >
            Tarjeta Plan
            <br />
            Cuota Simple
          </DasMasSelectorButton>
        )}
      </div>
    </div>
  );
};

export default DasMasSelector;
