import React from "react";
import "./paymentMethodButton.css";

export default function PaymentMethodButton({ onClick, children, icon }) {
  const iconElement = () => {
    switch (icon) {
      case "card":
        return cardIcon;

      case "cash":
        return cashIcon;

      default:
        return icon;
    }
  };

  return (
    <button className="payment-method-button" onClick={onClick}>
      <div className="payment-method-button-icon">{iconElement()}</div>
      <span>{children}</span>
    </button>
  );
}

const cardIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Contenido / Tarjeta" clipPath="url(#clip0_2849_636)">
      <path
        id="Vector"
        d="M28.3353 8.276H7.2369C5.77321 8.276 4.6128 9.417 4.6128 10.84L4.59961 26.2243C4.59961 27.6473 5.77321 28.7883 7.2369 28.7883H28.3353C29.799 28.7883 30.9726 27.6473 30.9726 26.2243V10.84C30.9726 9.417 29.799 8.276 28.3353 8.276ZM28.3353 26.2243H7.2369V18.5321H28.3353V26.2243ZM28.3353 13.4041H7.2369V10.84H28.3353V13.4041Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2849_636">
        <rect
          width="35.1639"
          height="35.1639"
          fill="white"
          transform="translate(0.204102 0.950073)"
        />
      </clipPath>
    </defs>
  </svg>
);

const cashIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6 10.9523H25.3936H23.6389H10.9436H9.08569C7.33105 10.9523 5.98926 12.2941 5.98926 14.0487V14.2552V16.0098V20.448V22.3059C5.98926 24.0605 7.33105 25.4023 9.08569 25.4023H9.29212H11.0468H23.7421H25.6C27.3546 25.4023 28.6964 24.0605 28.6964 22.3059V22.0995V20.3448V15.9066V14.0487C28.6964 12.2941 27.3546 10.9523 25.6 10.9523ZM22.6068 23.338H12.0789C11.7693 21.2737 10.1178 19.6223 8.05355 19.3127V17.0419C10.1178 16.7323 11.7693 15.0809 12.0789 13.0166H22.6068C23.0196 15.0809 24.5679 16.7323 26.6321 17.0419V19.3127C24.5679 19.6223 22.9164 21.2737 22.6068 23.338ZM25.6 13.0166C26.2193 13.0166 26.6321 13.4294 26.6321 14.0487V14.9777C25.7032 14.668 24.9807 13.9455 24.6711 13.0166H25.6ZM10.0146 13.0166C9.70498 13.9455 8.98248 14.668 8.05355 14.9777V14.0487C8.05355 13.4294 8.4664 13.0166 9.08569 13.0166H10.0146ZM9.08569 23.338C8.4664 23.338 8.05355 22.9252 8.05355 22.3059V21.377C8.98248 21.6866 9.70498 22.4091 10.0146 23.338H9.08569ZM24.6711 23.338C24.9807 22.4091 25.7032 21.6866 26.6321 21.377V22.3059C26.6321 22.9252 26.2193 23.338 25.6 23.338H24.6711Z"
      fill="white"
    />
    <path
      d="M17.6521 14.7713C15.8975 14.7713 14.5557 16.1131 14.5557 17.8678C14.5557 19.6224 15.8975 20.9642 17.6521 20.9642C19.4067 20.9642 20.7485 19.6224 20.7485 17.8678C20.7485 16.1131 19.4067 14.7713 17.6521 14.7713ZM17.6521 18.8999C17.0328 18.8999 16.62 18.4871 16.62 17.8678C16.62 17.2485 17.0328 16.8356 17.6521 16.8356C18.2714 16.8356 18.6842 17.2485 18.6842 17.8678C18.6842 18.4871 18.2714 18.8999 17.6521 18.8999Z"
      fill="white"
    />
  </svg>
);
