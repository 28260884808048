import React, { useState } from "react";
import { getPaymentLinks } from "../Services.js";
import Loader from "./Loader.js";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import PreviousStepButton from "./PreviousStepButton";
import LevelOneButton from "./LevelOneButton";

const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = (areacode, phonenumber) => {
  const number = parsePhoneNumber("+54" + areacode + phonenumber, "AR");
  return isValidPhoneNumber(number.formatNational(), "AR");
};

const PaymentForm = ({ isDasMas, setStep, usesCard }) => {
  const [isLoading, setLoading] = useState(false);
  const [inputValues, setinputValues] = useState({
    fname: { value: "", valid: "" },
    lname: { value: "", valid: "" },
    dni: { value: "", valid: "" },
    email: { value: "", valid: "" },
    areacode: { value: "", valid: "" },
    phonenumber: { value: "", valid: "" },
  });

  const prevStep = () => {
    document.querySelector(".content").style.opacity = 0;
    document.querySelector(".content").style.transform = "translateX(100px)";
    setTimeout(() => setStep(1), 200);
  };

  const handleButtonClick = async () => {
    if (!buttonEnabled) {
      return;
    }

    setLoading(true);
    const links = await getPaymentLinks(
      inputValues.fname.value,
      inputValues.lname.value,
      inputValues.email.value,
      inputValues.dni.value,
      inputValues.areacode.value,
      inputValues.phonenumber.value
    );

    if (usesCard) {
      window.location.replace(links.checkoutLink);
    } else {
      window.location.replace(links.invoiceLink);
    }
  };

  const handleInputChange = (name, value) => {
    switch (name) {
      case "fname":
      case "lname":
        setinputValues((inputValues) => ({
          ...inputValues,
          [name]: { value, valid: value.length > 1 ? "yes" : "no" },
        }));
        break;

      case "dni":
        setinputValues((inputValues) => ({
          ...inputValues,
          dni: {
            value,
            valid: value.length > 5 && value.length < 13 ? "yes" : "no",
          },
        }));
        break;

      case "email":
        setinputValues((inputValues) => ({
          ...inputValues,
          email: { value, valid: validateEmail(value) ? "yes" : "no" },
        }));
        break;

      case "areacode":
        setinputValues((inputValues) => ({
          ...inputValues,
          areacode: {
            value,
            valid: value.length >= 2 && value.length <= 6 ? "yes" : "no",
          },
        }));
        break;

      case "phonenumber":
        setinputValues((inputValues) => ({
          ...inputValues,
          phonenumber: {
            value,
            valid: validatePhone(inputValues.areacode.value, value)
              ? "yes"
              : "no",
          },
        }));
        break;

      default:
        return;
    }
  };

  const formText = () => {
    if (usesCard) {
      return {
        subtitle: "Pago con tarjeta",
        button: "Ir al checkout",
      };
    }

    return {
      subtitle: "Pago en efectivo",
      button: "Obtener cupón de pago",
    };
  };

  const buttonEnabled =
    inputValues.fname.valid === "yes" &&
    inputValues.lname.valid === "yes" &&
    inputValues.dni.valid === "yes" &&
    inputValues.email.valid === "yes" &&
    inputValues.areacode.valid === "yes" &&
    inputValues.phonenumber.valid === "yes";

  return (
    <div className="content">
      {isLoading && <Loader />}
      <div style={{ marginBottom: "32px", display: "flex", gap: "32px" }}>
        <PreviousStepButton onClick={prevStep} />
        <div>
          <h2 style={{ fontWeight: 600, fontSize: "28px" }}>
            Completá tus datos
          </h2>
          <div style={{ color: "#68758E", fontSize: "14px" }}>
            {formText().subtitle}
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{ display: "flex", flexDirection: "column", gap: "24px" }}
      >
        <label>
          Nombre
          <input
            className={`${inputValues.fname.valid === "no" ? "error" : ""}`}
            onChange={(e) => handleInputChange("fname", e.target.value)}
            placeholder="Escribí tu nombre…"
          />
        </label>
        <label>
          Apellido
          <input
            className={`${inputValues.lname.valid === "no" ? "error" : ""}`}
            onChange={(e) => handleInputChange("lname", e.target.value)}
            placeholder="Escribí tu apellido…"
          />
        </label>
        <label>
          DNI
          <input
            className={`${inputValues.dni.valid === "no" ? "error" : ""}`}
            type="number"
            onChange={(e) => handleInputChange("dni", e.target.value)}
            placeholder="XXXXXXXXX"
          />
        </label>
        <label>
          Email
          <input
            className={`${inputValues.email.valid === "no" ? "error" : ""}`}
            type="email"
            onChange={(e) => handleInputChange("email", e.target.value)}
            placeholder="ejemplo@zenlink.com"
          />
        </label>
        <div style={{ display: "flex", gap: "16px", alignItems: "end" }}>
          <label style={{ width: "30%" }}>
            Código de área
            <input
              className={`${
                inputValues.areacode.valid === "no" ? "error" : ""
              }`}
              type="number"
              onChange={(e) => handleInputChange("areacode", e.target.value)}
              placeholder="0351"
            />
          </label>
          <label style={{ flexGrow: 1 }}>
            Teléfono
            <input
              className={`${
                inputValues.phonenumber.valid === "no" ? "error" : ""
              }`}
              type="number"
              onChange={(e) => handleInputChange("phonenumber", e.target.value)}
              placeholder="15 5017 6006"
              disabled={inputValues.areacode.value === ""}
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LevelOneButton onClick={handleButtonClick} disabled={!buttonEnabled}>
            {formText().button}
          </LevelOneButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
