import React from "react";
import "./levelOneButton.css";

export default function LevelOneButton({ onClick, disabled, children }) {
  return (
    <button className="level-one-button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}
