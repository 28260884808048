export const formatMoney = (amount) => {
  if (!isNaN(parseFloat(amount))) {
    if (typeof window.Intl !== "undefined") {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      })
        .format(amount)
        .replace(" ", "");
    }

    return "$" + parseFloat(amount).toFixed(2);
  }

  return amount;
};
