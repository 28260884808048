import React from "react";
import { formatMoney } from "./helpers";
import PaymentMethodButton from "./PaymentMethodButton";

const descriptionStyles = {
  fontWeight: 500,
  fontSize: "32px",
  textAlign: "center",
  marginBottom: "26px",
};

const PaymentSelector = (props) => {
  const { nextStep, setUsesCard } = props;
  let { linkInfo } = props;

  if (linkInfo === 400) {
    return (
      <div className="content">
        <h2 style={descriptionStyles}>¡Algo salió mal!</h2>
        <div>No encontramos datos de pago asociados a este link.</div>
      </div>
    );
  }

  if (linkInfo === 403) {
    return (
      <div className="content">
        <h2 style={descriptionStyles}>¡Algo salió mal!</h2>
        <div>Acción temporalmente deshabilitada, contactese con la administración.</div>
      </div>
    );
  }

  const name = linkInfo.organization ? linkInfo.organization.name : "";
  const { ahora12Enabled } = linkInfo;
  const { showPaymentOptionsWhenAhora12IsEnabled } = linkInfo;

  const showCardAndCash = ahora12Enabled
    ? showPaymentOptionsWhenAhora12IsEnabled
    : true;

  const showAhora12 = ahora12Enabled;

  return (
    <div className="content">
      <h2 style={descriptionStyles}>{linkInfo.description}</h2>
      <div style={{ fontSize: "24px", textAlign: "center" }}>
        {"Pagar "}
        <strong>{formatMoney(linkInfo.amount)}</strong>
        {" a "}
        <strong>{name}</strong>
      </div>
      <div
        style={{
          display: "flex",
          gap: "52px",
          justifyContent: "center",
          marginTop: "52px",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {showCardAndCash && (
          <>
            <PaymentMethodButton
              onClick={() => {
                nextStep();
                setUsesCard(0);
              }}
              icon="cash"
            >
              Efectivo
            </PaymentMethodButton>
            <PaymentMethodButton
              onClick={() => {
                nextStep();
                setUsesCard(1);
              }}
              icon="card"
            >
              Tarjeta
            </PaymentMethodButton>
          </>
        )}
        {showAhora12 && (
          <PaymentMethodButton
            onClick={() => {
              nextStep();
              setUsesCard(1);
            }}
            icon="card"
          >
            Tarjeta Plan
            <br />
            Cuota Simple
          </PaymentMethodButton>
        )}
      </div>
    </div>
  );
};

export default PaymentSelector;
