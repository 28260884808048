import React from "react";
import "./previousStepButton.css";

export default function PreviousStepButton({ onClick }) {
  return (
    <button
      className="previous-step-button"
      onClick={onClick}
      aria-label="Volver"
    >
      {chevronLeft}
    </button>
  );
}

const chevronLeft = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5361 22.2344C18.5351 22.0174 18.4529 21.8096 18.3075 21.6567L11.3685 14.0411L18.3075 6.42548C18.3805 6.34776 18.4379 6.25531 18.4765 6.1536C18.5152 6.0519 18.5341 5.94301 18.5323 5.83338C18.5296 5.67046 18.481 5.51212 18.393 5.37859C18.3049 5.24506 18.1814 5.14241 18.038 5.08375C17.8947 5.02509 17.7381 5.01308 17.5883 5.04927C17.4385 5.08545 17.3023 5.16819 17.1971 5.28689L9.73903 13.4714C9.59967 13.6242 9.52175 13.8285 9.52175 14.0411C9.52175 14.2537 9.59967 14.458 9.73903 14.6108L17.1971 22.7953C17.3028 22.9163 17.4406 23.0006 17.5923 23.0372C17.744 23.0738 17.9026 23.0609 18.0473 23.0003C18.192 22.9397 18.3161 22.8341 18.4033 22.6975C18.4905 22.5608 18.5368 22.3995 18.5361 22.2344Z"
      fill="currentColor"
    />
  </svg>
);
